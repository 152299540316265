.portfolio {
  margin-bottom: 10rem;
  margin: 0 auto;
  width: 80%;

  .flex {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    gap: 6rem;  
  }
}

.header-portfolio {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 2rem;
  margin-bottom: 1em;
  
  .header-contain {
    display: flex;
    flex-direction: column;
    gap: 2em;
    justify-content: flex-start;
    align-items: flex-start;
    width:min-content;
  }
}

.works-container {
  display: flex;
  gap: 4em;
  flex-direction: column;
}

.Portfolio-Container {
  margin-top: 3rem;
}