@import '../../assets/styles/main.scss';

.Projects.contain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
  row-gap: 2rem;
  align-items: flex-start;

  h4 {
    font-weight: 700;
    color: #333333;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    padding: 1.8rem 0;

  }

  .case-study-contain {
    @include reset-link;
    display: flex;
    flex-direction:row;
    gap: 2em;
    .link {
      text-transform: uppercase;
    }
  }

  p.link {
      font-weight: 300;
      @include hover-underline($primary-brown-100);
    }
}

@media (min-width: 320px) {
  .project-card-grid {
    display: grid;
    width: 100%;
    row-gap: 2.5%;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .project-card-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
    row-gap: 3rem;
    column-gap: 5rem;
    margin: 0 auto;
  }
}
  

.projects-contain {
  margin: 0 auto;
}
  // display: flex;
  // flex-direction: row;
  // gap:  4rem;

  // .left {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2rem;  

  //   .case-study-contain {
  //     @include reset-link;
      
  //     p {
  //       @include hover-underline($primary-brown-100);
  //     }
  //   }
  // }
  // .right {
  //   width: 32rem;
  // }