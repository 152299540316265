@import '../../assets/styles/main.scss';

.bruinhive {
  margin: 0 auto;
  width: 80%;

  .flex {
    display: flex;
    flex-direction: row;
    gap: 6rem;  
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .description .small-paragraph {
      color: $primary-dark-grey-50;
    }
  }

  section {
    margin-bottom: 5em;
    // display: flex;
    // flex-direction: row;
    gap: 3rem;
    &.overview {
      flex-direction: column;
      gap: 3.8em;
    }
  }


  div.thumbnail-contain {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    img {
      width: 30vw;
      object-fit: fill;
      border-radius: 10px;
      box-shadow: $drop-shadow-med;
      
      @include basic-hover; 
    }

  }

  .sub-title {
    font-weight: 600;
    color: $primary-dark-grey-70;
  }

  .sub-descript-grid {
    display: grid;
    grid-template-columns: minmax(0, 100%) minmax(0, 50%);
    row-gap: 2rem;

    .sub-descript-child.skills {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .sub-descript-child {
      display: flex;
      flex-direction: column;
     // gap: 1rem;

      .sub-title {
        margin: 0;
      }
    }
    
  }
}

.sub-title {
  margin-bottom: 0.75rem;
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.descr-contain, .content div {

  color: $primary-dark-grey-50;
  transition: all 400ms cubic-bezier(0.215, 0.610, 0.355, 1);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  will-change: transform;

  &:hover {
    transform: scale(1.02);
    color: $primary-dark-grey-100;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  
  .small-paragraph a {
   // text-decoration: underline;
    //text-underline-offset: 3px;
    font-weight: $font-weight-semi-bold;
   // transition:  all 400ms cubic-bezier(0.215, 0.610, 0.355, 1);
    //@include hover-underline($accent-beige-100);
  }
}

.image-contain {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;


  img {
    box-shadow: $drop-shadow-med;
    width:30vw;
    border-radius: 10px;
    border: solid 3px $primary-dark-grey-90;
  }
}

.img-resize {
  width:60vw;
 // border-radius: 10px;
  //border: solid 3px $primary-dark-grey-90;
  margin: 1rem;
}

.final-style .img-resize {
  border-radius: 10px;
  box-shadow: $drop-shadow-med;
}
// .grid-inner {
//   display: grid;
//   grid-template-columns: 50% 50%;
//   gap: 1rem;

//   div {
//     width: 30vw;
//   }
// }

.home-page img {
  align-self:flex-start;

}