 @import '../../assets/styles/main.scss';

 @media (min-width: 320px) {
  .proj {
    width: 100%;
    h4 {
      font-size: $med-font-size;
    }
  
    p {
      font-size: $small-font-size;
    }
  }
 }

 @media (min-width: 1024px) {
  .proj {
    width: 100%;

    h4 {
      font-size: $small-font-size;
    }
  
    p {
      font-size: $xsmall-font-size;
    }
  }
 }

 
//  @media (min-width: 1024px) {
//   .proj {
//     width:calc(50% - 2rem);
//   }
//  }
 .proj {
  position:relative;
  transition: all 300ms ease-in;

  &:hover {
    transform:scale(0.98);
    .card-overlay {
      transition: 0.3s;
      opacity: 1;
    }
    img {
      transform:scale(0.98);
      transition: all 300ms ease-in-out;
      filter: blur(4px);
    }
  }

  a {
    @include reset-link;

    img {
      display: block;
      height:100%;
      width: 100%;
      border-radius: 1rem;
    }

    .card-overlay {
      width: 100%;
      border-radius: 1rem;
      //border: 4px solid white;
      top: 0;
      opacity: 0;
      position: absolute;
      height: 100%;
      background-color: rgba(0,0,0,0.6);
      display: flex;
      gap: 2rem;
      justify-content: center;
      flex-direction: column;
      transition: all 0.2s ease-in-out;
      box-shadow: $drop-shadow-med;
      border: solid 5px white;

      .header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      h4, p {
        color: white;
        width: 80%;
        margin: 0 auto;
        opacity: 1;
      }

      h4 {
        font-weight: $font-weight-medium;
        border-bottom: 2px solid #ffffff30;
        padding-bottom: 0.5rem;
      }

    }
  }
 }


// .proj.container {
//   @include reset-link;
//   max-width: 50vw;
//   margin: 0 auto;
//   border-radius: 3%;
//   position:relative;
//   transition: all 3000ms ease-in-out;

//   &:hover {
//     .card-overlay {
//       opacity: 1;
//     }
//   }
// }
// .header h4, .header p, .description p {
//   color: white;
// }

// .proj img {
//   display: block;
//   width: 100%;
//   height:auto;
//   box-shadow: $drop-shadow;
//   border-radius: 3%;
// //  @include outline-hover(white);
//   transition: all 300ms ease-in-out;
// //   // box-shadow: $drop-shadow;
// //   padding: 0;
// //   border-radius: 15px;
// //   overflow-clip-margin: none;
//   object-fit:cover;
// }

// // .proj a` {
// //   display: block;
// //   overflow: hidden;
// //   width: calc(40% - 1em);
// //   // box-shadow: $drop-shadow; 
// //   @include basic-hover;
// //   border-radius: 15px;
// // }

// .card-overlay {
//   width:100%;
//   top:0;
//   display: flex;
//   flex-direction: column;
//   gap: 1.25rem;
//   position:absolute;
//   border-radius: 3%;
//   background-color: $secondary-blue-70;
//   opacity: 0;
//   visibility: none;
//   transition: 300ms ease-in-out;
// }

// .container.proj:hover {
//   .card_overlay {
//   opacity: 1;
//   }

//   img {
//     -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
//     filter: blur(4px);
//   }
// }