@import '../../assets/styles/main.scss';

.not-found-contain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  text-align: center;
  height: 80vh;
  gap: 0.5em;

  // &:hover {
    
  // }
}
