.home {
  margin: 0 auto;
  width: 80%;

  .flex {
    display: flex;
    flex-direction: row;
    gap: 6rem;  
  }
  margin-bottom: 10rem;

  .right {
    display: flex;
    flex-direction: column;
    gap:1.5em;
    margin-top: 1rem;
  }

  .introduction {
    margin-bottom: 6em;
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  width: max-content;
  padding: 0rem 1rem;

  p {
    margin: 0;
    padding: 0;
  }
}


// .introduction.inner-contain {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
// }
// .content-contain {
//   display: flex;
//   flex-direction: row;
//   gap: 3.5em;
// }

// .content-child {
//   display: flex;
//   flex-direction: column;
//   gap: 3em;
// }

// .text-contain {
//   display: flex;
//   flex-direction: column;
//   gap: 4em;
// }

// #portfolio {
//   gap: 50%;

//   .divider {
//     border-bottom: solid 2px $light-green-50;
//     width:90%;
//     line-height:0em;
//     align-self: center;
//   }
// }

// .grid.portfolio{
//   grid-template-columns: repeat(2, 50%);
// }