//Store global mixins, like utility functions, that can be reused across various components and pages.
@import '../styles/reset';

@mixin horizontal-list {
  ul {
    @include reset-list;
    line-height: 175%;
    // display:flex;
    // flex-direction:row;
    // flex-wrap:nowrap;
    // flex: auto;
    // gap: 60px;
    // justify-content:flex-end;
    display: grid;
    gap:3rem;
    grid-template-columns: repeat(4, minmax(0, 1.5fr));
    text-align:center;    
  }
}
//add drop shadow mixin
//add mixin for removing bullet point from lists
@mixin standard-padding {
  padding: 60px 160px;
}
@mixin hover-underline($color) {
  display: inline-block;
  transition: all 300ms ease-in-out;
  // padding-bottom: $offset;
  will-change: contents;
  &:hover {
    color: $color;
  }
  &:after {
    display: block;
    border-bottom: solid 1px $color;// While hovering
    content: '';
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    transform-origin: 100% 50%;
  }
  
  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
    transition: transform 300ms ease-in-out;
  }
}

@mixin outline-hover($color) {
  flex-grow: 1;
  flex-shrink: 1;
  // filter: $drop-shadow;
  will-change: contents;
  transition: all 300ms ease-in-out;
  &::before {
    outline: $color;
  }
  &:hover {
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
    outline: 3px solid $color;
    outline-offset: 4px;
    transform: scale(0.98);
  }
}

@mixin basic-hover {
  flex-grow: 1;
  flex-shrink: 1;
  // filter: $drop-shadow;
  transition: all 300ms ease-in-out;

  &:hover {
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
    transform: scale(0.98);
  }
}

@mixin large-hover {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  // filter: $drop-shadow;
  transition: all 300ms ease-in-out;

  &:hover {
    justify-content: flex-end;
    //box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
    transform: scale(1.1);
    
  }
}
