// Include CSS reset or normalize styles to ensure consistent rendering across different browsers.

body {
  width: 100vw;
  min-height: 100vh;
}

body, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin reset-link {
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: inherit;

  &:default, &:hover, &:active, &:after, &:visited {
     color: inherit;
  }
}