@import '../../assets/styles/main.scss';

.Contact {
  display: flex;
  flex-direction:column;
  gap: 2rem;
}

.contact-contain {
  @include outline-hover(white);
  background-color: $light-brown-100;
  border-radius:3rem 5rem 1.25rem 5rem;
  //filter: $drop-shadow;
  padding: 2rem 4rem 3rem 3rem;
  max-width: 50rem;
  box-shadow: $drop-shadow-med;
  a {
    @include reset-link;
    width:min-content;
    outline: none;
    margin-bottom: 1.4rem;
    
    p {
      @include hover-underline($primary-dark-grey-100);
      color: $secondary-blue-100;
    }
    // @include hover-underline($secondary-blue-50);
  }

  h4 {
    margin-bottom: 1em;
  }
}

.contact-list {
  flex-direction:column;
  gap: 0.5em;
  a {
    display: flex;
    align-items: center;
    gap: 1em;
    padding-left:2em;
  }
}

.vertical {
  justify-content: flex-start;
  gap: 1rem;
}

.horizontal {
  gap: 3rem;
}
