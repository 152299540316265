@import '../../assets/styles/main.scss';

.button-contain {
  background-color: transparent;
  border-radius: 10px;
  border: solid 1px $secondary-blue-50;
  color: $secondary-blue-50;
  transition: all 200ms ease-in-out;
  @include reset-link;
  padding: 0.6rem;
  display: block;
  width: max-content;
  margin-top: 1rem;
  
  &:hover {
    // background-color: $light-green-100;
    // color: white;
    box-shadow: 2px 2px 0 0 $light-green-100;
  }

}