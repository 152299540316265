//Define global variables here, such as colors, fonts, and spacing, which will be used throughout the project.

/**
 * Colors
 * ----------------------------------------------------------------------------
 * This file defines the color palette used in the project. Modify these
 * variables to customize the colors according to your design requirements.
 */

 /* Primary Colors */
$primary-dark-grey-100: #3b3b3b;
$primary-dark-grey-90: rgba(51, 51, 51, 0.9);
$primary-dark-grey-70: rgba(51, 51, 51, 0.7);
$primary-dark-grey-50: rgba(51, 51, 51, 0.5);
$primary-dark-grey-30: rgba(51, 51, 51, 0.3);
$primary-brown-100: rgb(106, 95, 101);
$primary-brown-50: rgba(98, 92, 95, 0.5);
$background-grey-100: rgb(242, 242, 242);

 /* Secondary Colors */
$secondary-blue-100: rgb(83, 94, 114);
$secondary-blue-70: rgba(83, 94, 114, 0.7);
$secondary-blue-50: rgba(83, 94, 114, 0.5);
$secondary-blue-30: rgba(83, 94, 114, 0.3);
$secondary-blue-10: rgba(83, 94, 114, 0.1);

 /* Light Colors */
$light-brown-100: rgb(193, 183, 178);
$light-brown-50: rgba(193, 183, 178, 0.5);
$light-brown-30: rgba(193, 183, 178, 0.3);
$light-green-100: rgb(123, 122, 117);
$light-green-80: rgba(123, 122, 117, 0.8);
$light-green-50: rgba(123, 122, 117, 0.5);
$light-green-30: rgba(123, 122, 117, 0.3);
$light-green-10: rgba(123, 122, 117, 0.1);


 /* Accent Colors */
 $accent-beige-100: #A6978B;
 $accent-beige-50: rgba(166, 151, 139,0.5);

