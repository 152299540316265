@import '../../assets/styles/main.scss';

.footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 0rem;
  padding-top: 3rem;

  .divider {
    width: 100%;
  }
}

.footer-contents {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.logo-left {
  display:flex;
  flex-direction:column;
  gap: 1rem;
  padding-top: 1rem;

  h4 {
    line-height: 170%;
  }
}

.nav-links {
  display: flex;
  flex-direction: column;
 
  border-left: solid 1px $accent-beige-50;
  gap: 1rem;
  padding-left: 2rem;
  margin-left: 2rem;

  & .grey {
    color: $primary-dark-grey-50;
  }
  a {
    @include reset-link;
    width:min-content;
    outline: none;
    margin-bottom: 0.5rem;
    
    p {
      @include hover-underline($primary-dark-grey-100);
      color: $secondary-blue-100;
    }

  }

}

.navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
