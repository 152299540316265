@import '../styles/colors';
/**
 * Fonts
 * ----------------------------------------------------------------------------
 * This file defines the font families, sizes, and other typography-related styles.
 * Modify these variables to customize the fonts used in the project.
 */

 /* External Font Import */
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&family=Open+Sans:wght@300;400;600;700&display=swap');

/* Font Family */
$primary-font: 'Montserrat', sans-serif;

/* In the _typography.scss file, you generally define the font families to be used 
 * throughout your application and their associated font sizes, line heights, and other 
 * typographic styles. However, you do not directly import the font files (e.g., .woff, 
 * .woff2, .ttf, etc.) into this file. */

/* Font Sizes */
$xlarge-font-size: 3.125rem;
$large-font-size: 1.875rem;
$med-font-size: 1.5rem;
$small-font-size: 1rem;
$xsmall-font-size: 0.875rem;

/* Font Weights */
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

/* Font Style */
$font-style-regular: normal;
$font-style-italic: italic;

/* Line Heights */
$line-height-large: 180%;
$line-height-med: 122%;
$line-height-reg: normal;

/* Typography Usage */
body {
  font-family:$primary-font;
  font-weight: $font-weight-regular;
  font-size: $xsmall-font-size;
  color: $primary-dark-grey-100;
}

/* Headings */
h1, h2 {
  color: $primary-brown-100;
}

h1, h5{
  font-weight: $font-weight-medium;
}

h2, h3{
  font-size: $large-font-size;
}

h2, h3, h4{
  line-height: $line-height-reg;
}

h1 {
  font-size: $xlarge-font-size;
  line-height: $line-height-med;
}

h2 {
  font-weight: $font-weight-semi-bold;
  margin-bottom: 1rem;
}

h3 {
  font-style: $font-style-italic;
  color: white;
  font-weight: $font-weight-light;
  
}

h4 {
  font-size: $med-font-size;
  color: $primary-dark-grey-50;
  font-weight: $font-weight-regular;
}

/* Paragraphs */
p {
  font-size: $small-font-size;
  line-height: $line-height-large;
  //margin-bottom: 1rem;
}

#accent-paragraph{
  font-weight: $font-weight-medium;
  color: $accent-beige-100;
}

p.small-paragraph{
  font-size: $xsmall-font-size;
}

/* Links */

a {
  @include reset-link;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .description .small-paragraph {
    color: $primary-dark-grey-50;
  }
}