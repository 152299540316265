/** add breakpoints for when it becomes mobile/tablet and it collapses 
  * into the three bars **/
@import '../../assets/styles/main.scss';
nav .container{
  /* layout */
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  flex-wrap:nowrap;
  gap: 2rem;
  @include horizontal-list;
  height: 5em;
  padding-top: 3em;
  max-width: unset;
}
.logo {
  flex: 1;
  display: flex;
  position: relative;
  @include reset-link;
  padding: 3em;
}

.logo-overlay {
  position: absolute;
  inset: 0;
  width: 100%;
  //background-color: rgb(185,79,83);
}
nav {
  li a {
    @include reset-link;
    color: $primary-dark-grey-100; 
    @include hover-underline($primary-dark-grey-30);
  }
}

.activeNav {
  text-shadow:  0 0px $primary-dark-grey-30, 0 0 0.7px $primary-dark-grey-30;
  transform: none;
  color: $primary-dark-grey-30;
  &::after{
    transform: scaleX(1);
  }
}

// @media (min-width:320px) {
//   /*collapsing menu*/
// }