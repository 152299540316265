@import '../../assets/styles/main.scss';

.dots-contain {
  gap:1em;
  flex-wrap:nowrap;
  justify-content: center;
}

.dots-child {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

#brown {
  background-color: $primary-brown-100;
}

#blue {
  background-color: $secondary-blue-100;
}

#beige {
  background-color: $accent-beige-100;
}

.dots-contain {
  display: flex;
  &.horizontal {
    flex-direction: row;
  }

  &.vertical{
    flex-direction:column;
  }

  &.center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }
}
// .dots-contain .horizontal {
//   margin-bottom:1rem;
// }

// .dots-contain .vertical {
//   margin-right: 1rem;
// }
