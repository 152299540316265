// Define layout-related styles, such as grids, flexbox, and spacing between sections.
@mixin center-flex($direction){
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

.container {
  width: 80%;
  max-width:80em;
  margin: 0 auto;

}

.flex {
  display: flex;
}

.inner-introduction {
  display: flex;
  flex-direction: row;
  // flex-shrink: 1;
  // flex-grow: 1;
  gap: 2em;
  .left, .text-contain {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }

}

.horizontal {
  flex-direction: horizontal;
}

.vertical {
  flex-direction: column;
}

.top-align {
  align-items: flex-start;
}

.no-wrap {
 text-wrap: nowrap;
}

.grid {
  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-column-gap: 6.5em;
  // grid-row-gap: 4em;
  margin: 0 auto;
  justify-content: center;
}

.width-1\/12 {
  grid-column: span 1;
}

.width-2\/12 {
  grid-column: span 2;
}

.width-3\/12 {
  grid-column: span 3;
}

.width-4\/12 {
  grid-column: span 4;
}

.width-5\/12 {
  grid-column: span 5;
}

.width-6\/12 {
  grid-column: span 6;
}

.width-7\/12 {
  grid-column: span 7;
}

.width-8\/12 {
  grid-column: span 8;
}

.width-9\/12 {
  grid-column: span 9;
}

.width-10\/12 {
  grid-column: span 10;
}

.width-11\/12 {
  grid-column: span 11;
}

.width-12\/12 {
  grid-column: span 12;
}

.divider {
  border-top: 1.5px solid $light-green-50;
  width: 40%;
}

.container.introduction, .container.projects {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

ol, ul {
  display: flex;
  gap: 1.125rem;
  flex-direction: column;

  li {
    line-height: 1.5rem;
  }
}

.overview p, .descr-contain p{
  margin-bottom: 1rem;
}

.descr-contain {
  margin-bottom: 2em;
}

.left .descr-contain {
  margin-bottom: 1rem;
}

.grid-contain section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 1350px) {
  .grid-contain section {
    display: grid;
    grid-template-columns: 20% 80%;
    gap: 3rem;
  }
}
