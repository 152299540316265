@import '../../assets/styles/main.scss';

.Technologies {
  display: flex;
  flex-direction:row;
  gap: 2rem;
  height: fit-content;
  width: fit-content;
  justify-self: flex-end;
  h4, p {
    color: white;
  }
}

.technologies-contain { 
  @include outline-hover(white);
  background-color: $secondary-blue-100;
  border-radius: 6.25rem 1.25rem 8.75rem 5rem;
  box-shadow: $drop-shadow-med;
  //filter: $drop-shadow;
  padding: 3rem 4rem;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.3rem
}
//   a {
//     @include reset-link;
//     color: $secondary-blue-100;
//     width:min-content;
//     outline: none;
//     margin-bottom: 1.4rem;
    
//     p {
//       @include hover-underline($primary-dark-grey-100);
//     }
//     // @include hover-underline($secondary-blue-50);
//   }

//   h4 {
//     margin-bottom: 1em;
//   }
// }

.tech-list {
  display: flex;
  flex-direction:column;
  gap: 1.3rem;
}

// .container .flex .vertical {
//   justify-content: flex-start;
//   gap: 1rem;
// }

// .container.flex.horizontal {
//   gap: 3rem;
// }
