@import '../../assets/styles/main.scss';

.subheader-contain {
  display: flex;
  justify-content: flex-start;
  //padding: 2rem 10rem 2rem 3rem;
  //border-radius:5rem 3rem 12.5em 2rem;
  // border-radius: 70px 70px 200px 30px;
  //background-color: $secondary-blue-100;
 // width: 20vw;
  //min-width:400px;
 // min-width: 5rem;
  //@include outline-hover(white);
 //flex-grow:unset;
 // flex-shrink:unset;
 p {
  text-transform: uppercase;
  color: $primary-dark-grey-50;
}
}

// .subheader-contain[title='home-icon']{
//   width: 8.375rem;
//   height: 8.4375rem;
//   h3 {
//     display:none;
//     &::after{
//       content:attr)
//     }
//   }
// }