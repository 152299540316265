@import '../assets/styles/main.scss';

.about .right {
  max-width: 50rem;
}

.about {
  .introduction {
    margin-bottom: 1em;
  }
}

.header-contain {
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
}

.img-contain {
  display:flex;
  flex-direction:column;
  align-content:center;
  flex-wrap: wrap;
  gap: 2rem;
  
  img {
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.25);
    border-radius: 400px;
    width:15rem;
    height: auto;
    @include basic-hover;
  }
}

.about {
  margin: 0 auto;
  width: 80%;

  .flex {
    display: flex;
    flex-direction: row;
    gap: 6rem;  
  }

}
  section {
    margin-bottom: 5em;
    // display: flex;
    // flex-direction: row;
    gap: 3rem;
    &.overview {
      flex-direction: column;
      gap: 3.8em;
    }
  }

.obsessions-contain {
  margin: 0 auto 3rem auto;
}

.obsession-grid {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  width:100%;
  justify-content: center;
  margin: 0 auto;
}

.obsession-grid-child {
  display: flex;
  background-color: $light-green-100;
  text-align:center;
  height: 20rem ;
  justify-content: center;
  align-items: center;
  padding: 0 2.5rem;
  border-radius: 6em  ;
  box-shadow: $drop-shadow;

  @include outline-hover(white);
  h4 {
    width: 8.75rem;
    color: white;
    font-weight: 300;
    line-height: $line-height-med;
    
  }
}

.obsession-descr {
  p {
    margin-bottom: 2rem;
  }
}